<template>
  <v-container fluid align-start fill-height class="pt-0">
    <MainModal
        :main="{ component: 'FlatCourtCase', title: 'Судові справи' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_court_case)"
        :modal="show_court_case_dialog"
        :max-width="960"
        @updateItemModal="courtCaseUpdateItemModal"
    />
    <v-row :justify="short_form ? 'start' : 'center'" style="height: 100%">
      <v-col cols="12" class="pa-1 pt-2">
        <v-card tile elevation="1" style="height: 100%">
          <v-card-title class="pa-0 pr-1" v-if="!short_form">
            <v-toolbar elevation="0" class="pa-0">
              <v-toolbar-title>
                Претензії/Судові справи
              </v-toolbar-title>
              <v-spacer/>
              <v-tooltip bottom color="success">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="openCourtCaseCreateDialog"
                         v-bind="attrs"
                         v-on="on"
                         class="grey lighten-4 mr-1">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Створити нову претензію/судову справу</span>
              </v-tooltip>
            </v-toolbar>
          </v-card-title>
          <v-data-table
              :headers="short_form ? tableHeaderShort : tableHeader"
              :items="items"
              :single-select="true"
              style="height: calc(100% - 40px)"
              no-data-text="Дані відсутні. Жодного запису не виявлено"
              :disable-pagination="short_form"
              :hide-default-footer="short_form"
              :footer-props="{
                  showFirstLastPage: true,
                  itemsPerPageText: 'Рядків на сторінку',
                  itemsPerPageOptions: [15, 30, -1]

                }"
              class="custom-table custom-table-full event-logger-table slim"
              @click:row="onCourtCaseItemClick"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon
                  :color="item.document_type === 'court_case' ? 'error' : 'secondary'"
                  :size="23"
              >
                <template v-if="item.document_type === 'court_case'">
                  mdi-gavel
                </template>
                <template v-else>
                  mdi-email-fast-outline
                </template>
              </v-icon>
            </template>
            <template v-slot:item.notes="{ item }">
              <span v-if="item.notes">
                {{ item.notes }}
              </span>
              <span v-else>
                Нотатки відсутні
              </span>
            </template>
            <template v-slot:item.document_type="{ item }">
              <template v-if="item.document_type === 'pretense'">
                Претензія
              </template>
              <template v-if="item.document_type === 'court_case'">
                Судова справа
              </template>
            </template>
            <template v-slot:item.current_detail_date="{ item }">
              {{ item.current_detail_date | formatDate }}
            </template>
            <template v-slot:item.current_detail_status="{ item }">
              <template v-if="item.document_type === 'pretense'">
                {{ getPretenseStatus(item.current_detail_status) }}
              </template>
              <template v-else>
                {{ getCourtCaseStageOfEnforcement(item.current_detail_status) }}
              </template>
            </template>
            <template v-slot:item.current_detail_document="{ item }">
              <template v-if="item.current_detail_document">
                {{ getCourtCaseDocument(item.current_detail_document) }}
              </template>
              <template v-else>
                ...
              </template>
            </template>
            <template v-slot:item.balance_sum="{ item }">
                {{ item.balance_sum | formatNumber }}
            </template>
            <template v-slot:item.costs="{ item }">
              {{ item.costs | formatNumber }}
            </template>
            <template v-slot:item.its_closed="{ item }">
              <v-simple-checkbox :value="item.its_closed" color="success" class="mt-0"/>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import courtCaseAPI from "@/utils/axios/flat_court_case";
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
import {getCourtCaseStageOfEnforcement, getCourtCaseDocument, getPretenseStatus} from "@/utils/icons"

export default {
  name: "FlatCourtCase",
  props: ['person_hash', 'short_form'],
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters(
        {
          flat_current: 'getFlat',
        }
    )
  },
  data() {
    return {
      items: [],
      tableHeader: [
        { text: '', value: 'icon', width: 24 },
        { text: 'Тип документа', value: 'document_type', width: 180},
        { text: 'Дата', value: 'current_detail_date', width: 110},
        { text: 'Статус', value: 'current_detail_status', width: 200},
        { text: 'Документ', value: 'current_detail_document'},
        { text: 'Борг', value: 'balance_sum', width: 100 },
        { text: 'Витрати', value: 'costs', width: 110},
        { text: 'Закрито', value: 'its_closed', width: 100},
        { text: 'Нотатки', value: 'notes'},
      ],
      tableHeaderShort: [
        { text: '', value: 'icon', width: 24 },
        { text: 'Дата', value: 'current_detail_date', width: 110},
        { text: 'Статус', value: 'current_detail_status', width: 200},
      ],
      selected_court_case: {},
      show_court_case_dialog: false
    }
  },
  methods: {
    getCourtCaseStageOfEnforcement,
    getCourtCaseDocument,
    getPretenseStatus,
    updateItemModal(payload) {
      this.detail_row.id = null
      this.detail_row.flat_id = null
      this.detail_row.document_type = null
      this.detail_row.court_fee = null
      this.detail_row.litigation_costs = null
      this.detail_row.balance_month = null
      this.detail_row.balance_sum = null
      this.detail_row.its_closed = null
      this.detail_row.close_date = null
      this.detail_row.notes = null
      this.detail_row.date = null
      this.detail_row.certificate_owner = null
      this.detail_row.certificate_date = null
      this.detail_row.certificate_number = null
      this.detail_row.certificate_value = null

      this.show_detail = false

      if (payload) {
        if (payload.payload === 'fetch') {
          this.fetch()
          this.$emit('afterFetch', true)
        }
      }
    },
    fetch() {
      courtCaseAPI.get_all(this.person_hash)
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.commit(ALERT_SHOW, { text: error, color: 'error lighten-1' })
          });
    },
    courtCaseUpdateItemModal(payload) {
      this.show_court_case_dialog = false
      this.selected_court_case = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.fetch()
          this.$emit('afterFetch', true)
        }
      }
    },
    openCourtCaseCreateDialog() {
      this.selected_court_case = {
        flat_id: this.flat_current.id,
        document_type: 'pretense'
      }
      this.show_court_case_dialog = true
    },
    onCourtCaseItemClick(payload) {
      this.selected_court_case = JSON.parse(JSON.stringify(payload))
      this.show_court_case_dialog = true
    }
  },
  created() {
    this.fetch()
  }
}
</script>

<style scoped lang="scss">

</style>